<template>
  <div class="technology">
    <!-- 成型工艺智能设置 -->
    <div class="content">
      <h1>成型工艺智能设置</h1>
      <div style="height: 50px"></div>

      <div class="industry-introduction">
        <h3>功能介绍</h3>
        <p>它结合了数据分析、模型建立和优化算法，以实现更高效、可靠和精确的成型工艺。通过成型工艺智能设置，可以实现以下好处：提高生产效率：智能设置可以帮助找到最优的工艺参数组合，从而提高生产效率和产量;优化产品质量：通过优化成型工艺，可以减少缺陷和不良品率，提高产品质量和一致性；节约资源：智能设置可以帮助节约能源和原材料的使用，降低生产成本；减少人为错误操作：自动化的智能设置可以减少人为误操作和调整带来的影响，提高生产的稳定性和可靠性。</p>
      </div>
    </div>

    <div class="core-function">
      <h1>核心功能</h1>
      <div class="fun-content">
        <video src="@/assets/images/product/testmode/test-mode7.mp4" controls width="1200px"></video>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.fun-content {
  width: 1200px;
  margin: 0 auto;
}
</style>